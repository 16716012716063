const MenuItems = [
    {
        title: 'Sobre',
        url: '#About',
        cName: 'nav-links'
    },
    {
        title: 'Projetos',
        url: '#Work',
        cName: 'nav-links'
    },
    {
        title: 'Casamentos',
        url: '#Wedding',
        cName: 'nav-links'
    },
    {
        title: 'Equipe',
        url: '#Team',
        cName: 'nav-links'
    },
];
export default MenuItems;