import wedd1 from "./altar.jpg";
import wedd2 from "./casal.jpg";
import wedd3 from "./casalcosta.jpg";

const wedding = [
    {
        url:wedd1,
        alt: "1 wedding image"
    },
    {
        url:wedd2,
        alt:"9 wedding image"
    },
    {
        url:wedd3,
        alt:"3 wedding image"
    }
];

export default wedding;