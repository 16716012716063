import image1 from "./fashion-film.png";
import image2 from "./frames-para-o-site-da-super.png";

const Images = [
    {
        src:image1,
        alt:"fashion film",
    },
    {
        src:image2,
        alt:"anything"
    },

];

export default Images;